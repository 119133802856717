import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './App'; // Make sure useAuth is exported from App.js

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from auth context
  const API_URL = 'https://qualai.argentjobs.co.in/login.php';

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form with:', { username, password });
    try {
      console.log('Sending request to:', API_URL);
      const response = await axios.post(API_URL, {
        username,
        password,
      });
      console.log('Received response:', response.data);
      if (response.data.success) {
        console.log('Login successful, navigating to dashboard');
        login(); // Call the login function from auth context
        navigate('/dashboard');
      } else {
        console.log('Login failed:', response.data.error);
        setError(response.data.error || 'Invalid username or password.');
      }
    } catch (err) {
      console.error('Error during login:', err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
        setError(`Server error: ${err.response.status}`);
      } else if (err.request) {
        // The request was made but no response was received
        console.error('No response received');
        setError('No response from server. Please try again.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', err.message);
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <section className="auth d-flex">
      <div className="auth-left bg-main-50 flex-center p-24">
        <img src="Login.png" alt="" />
      </div>
      <div className="auth-right py-40 px-24 flex-center flex-column">
        <div className="auth-right__inner mx-auto w-100">
          <a href="/" className="auth-right__logo">
            <img src="l2.png" alt="" />
          </a>
          <h2 className="mb-8">Welcome Back! 👋</h2>
          <p className="text-gray-600 text-15 mb-32">Please sign in to your account and start the adventure</p>

          <form onSubmit={handleSubmit}>
            <div className="mb-24">
              <label htmlFor="username" className="form-label mb-8 h6">Email or Username</label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control py-11 ps-40"
                  id="username"
                  placeholder="Type your username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <span className="position-absolute top-50 translate-middle-y ms-16 text-gray-600 d-flex">
                  <i className="ph ph-user"></i>
                </span>
              </div>
            </div>
            <div className="mb-24">
              <label htmlFor="password" className="form-label mb-8 h6">Password</label>
              <div className="position-relative">
                <input
                  type="password"
                  className="form-control py-11 ps-40"
                  id="password"
                  placeholder="Enter Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="toggle-password position-absolute top-50 inset-inline-end-0 me-16 translate-middle-y ph ph-eye-slash"></span>
                <span className="position-absolute top-50 translate-middle-y ms-16 text-gray-600 d-flex">
                  <i className="ph ph-lock"></i>
                </span>
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="mb-32 flex-between flex-wrap gap-8">
              <div className="form-check mb-0 flex-shrink-0">
                <input
                  className="form-check-input flex-shrink-0 rounded-4"
                  type="checkbox"
                  id="remember"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="form-check-label text-15 flex-grow-1" htmlFor="remember">Remember Me</label>
              </div>
              <a href="/forgot-password" className="text-main-600 hover-text-decoration-underline text-15 fw-medium">Forgot Password?</a>
            </div>
            <button type="submit" className="btn btn-main rounded-pill w-100">Sign In</button>
          </form>

          <p className="mt-32 text-gray-600 text-center">
            New on our platform?
            <a href="/sign-up" className="text-main-600 hover-text-decoration-underline">Create an account</a>
          </p>
          <div className="divider my-32 position-relative text-center">
            <span className="divider__text text-gray-600 text-13 fw-medium px-26 bg-white">or</span>
          </div>
          <ul className="flex-align gap-10 flex-wrap justify-content-center">
            <li>
              <a href="https://www.facebook.com" className="w-38 h-38 flex-center rounded-6 text-facebook-600 bg-facebook-50 hover-bg-facebook-600 hover-text-white text-lg">
                <i className="ph-fill ph-facebook-logo"></i>
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com" className="w-38 h-38 flex-center rounded-6 text-twitter-600 bg-twitter-50 hover-bg-twitter-600 hover-text-white text-lg">
                <i className="ph-fill ph-twitter-logo"></i>
              </a>
            </li>
            <li>
              <a href="https://www.google.com" className="w-38 h-38 flex-center rounded-6 text-google-600 bg-google-50 hover-bg-google-600 hover-text-white text-lg">
                <i className="ph ph-google-logo"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;