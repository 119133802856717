import React, { useState, createContext, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import LoginPage from './LoginPage';
import { FaHome, FaUsers, FaArchive, FaFileAlt, FaPalette, FaFolder, FaClipboardList } from 'react-icons/fa';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

// Create an AuthContext
const AuthContext = createContext(null);

// AuthProvider component
const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = () => setIsLoggedIn(true);
  const logout = () => setIsLoggedIn(false);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? children : null;
};

// Sidebar Component
const Sidebar = () => (
  <aside className="sidebar">
    <div className="logo">
      <span className="logo-icon">t</span>
      <span className="logo-text">Learning Content</span>
    </div>
    <nav>
      <ul>
        <li><FaHome className="icon" /> Recents</li>
        <li><FaUsers className="icon" /> Shared Content</li>
        <li><FaArchive className="icon" /> Archived</li>
        <li><FaFileAlt className="icon" /> Templates</li>
      </ul>
    </nav>
    <div className="favorites">
      <h3>Favorites<span>3</span></h3>
      <ul>
        <li><FaPalette className="icon" /> Figma Basic</li>
        <li><FaFolder className="icon" /> Folder NEW 2024</li>
        <li><FaClipboardList className="icon" /> Assignment 101</li>
      </ul>
    </div>
    <div className="projects">
      <h3>Projects<span>2</span></h3>
      <ul>
        <li><FaPalette className="icon" /> Figma basic</li>
        <li><FaFolder className="icon" /> Fikri studio</li>
      </ul>
    </div>
  </aside>
);

// Content Header Component
const ContentHeader = () => (
  <header className="content-header">
    <h1>General Knowledge & Methodology</h1>
    <div className="content-tabs">
      <span className="active">Details Content</span>
      <span>Report Issue</span>
      <span>Discussion</span>
    </div>
  </header>
);

const AssigneeCard = ({ name, role, imageUrl }) => (
  <li className="py-3 sm:py-4">
    <div className="flex items-center space-x-4">
      <div className="shrink-0">
        <img src={imageUrl} alt={name} className="w-8 h-8 rounded-full" />
      </div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-sm font-medium text-gray-900">{name}</p>
        <p className="truncate text-sm text-gray-500">{role}</p>
      </div>
      <div className="inline-flex items-center text-base font-semibold text-gray-900">...</div>
    </div>
  </li>
);

// Content Details Component
const ContentDetails = () => (
  <div className="content-details">
    <span><FaFileAlt className="icon" /> 3 sections & 4 Chapters</span>
    <span><FaClipboardList className="icon" /> 3 Hours Estimation</span>
    <span><FaPalette className="icon" /> 100 Points</span>
    <span><FaUsers className="icon" /> Certificate of Completion</span>
    <span><FaFolder className="icon" /> No due date for this content</span>
  </div>
);

// Trainer Component
const Trainer = ({ name, role, department, imageUrl }) => (
  <div className="trainer">
    <img src={imageUrl} alt={name} />
    <div>
      <p><strong>{name}</strong></p>
      <p>{role}</p>
      <p>{department}</p>
    </div>
  </div>
);

// Trainers Section
const Trainers = () => (
  <div className="trainers">
    <h2>Trainer <button className="add-trainer">+ Add trainer</button></h2>
    <div className="trainers-list">
      <Trainer name="Bagus Fikri" role="Project Manager at Fikri Studio" department="Author • Admin Dept." imageUrl="/api/placeholder/40/40" />
      <Trainer name="Raihan Fikri" role="Design Manager at Fikri Studio" department="Author • Admin Dept." imageUrl="/api/placeholder/40/40" />
      <Trainer name="Tea Assiddiq" role="Project Manager at Fikri Studio" department="Admin Dept." imageUrl="/api/placeholder/40/40" />
    </div>
  </div>
);

// Page Content Component
const PageContent = () => {
  const [sections, setSections] = useState([
    { id: 1, title: "Design Introduction", chapters: 2, isOpen: true },
    { id: 2, title: "Conducting a heuristic evaluation", chapters: 5, isOpen: false },
    { id: 3, title: "Final Trough on UI Heuristic", chapters: 2, isOpen: false }
  ]);

  const toggleSection = (id) => {
    setSections(sections.map(section =>
      section.id === id ? { ...section, isOpen: !section.isOpen } : section
    ));
  };

  return (
    <div className="page-content">
      <div className="page-content-header">
        <h2>Page content</h2>
        <button className="add-section">+ Add section</button>
      </div>
      <p>3 section • 4 chapters</p>
      <div className="sections-container">
        {sections.map(section => (
          <div key={section.id} className={`section ${section.isOpen ? 'open' : ''}`}>
            <div className="section-header" onClick={() => toggleSection(section.id)}>
              <span className="section-title">{section.title}</span>
              <span className="chapter-count">{section.chapters} chapter</span>
              <span className="toggle-icon">{section.isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
            </div>
            {section.isOpen && section.id === 1 && (
              <div className="section-content">
                <p><FaFileAlt className="icon" /> Component of Design</p>
                <p><FaFileAlt className="icon" /> Chapter 1: Visibility of system status</p>
                <p className="add-chapter">+ Add new chapter</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// Assignee Section
const AssigneeSection = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="assignee-card">
      <div className="mb-4 flex items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900">Assignees</h5>
        <span className="text-sm font-medium text-cyan-600">32 assignees</span>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200">
          <AssigneeCard name="Adit Irawan" role="UI/UX Designer" imageUrl="/api/placeholder/32/32" />
          <AssigneeCard name="Arif Aryanto" role="Design" imageUrl="/api/placeholder/32/32" />
          <AssigneeCard name="Ardhi Mubarak" role="UI/UX Designer" imageUrl="/api/placeholder/32/32" />
          <AssigneeCard name="Eka Sunabari" role="Design" imageUrl="/api/placeholder/32/32" />
        </ul>
      </div>
      <div className="mt-4">
        <a href="#" className="text-sm font-medium text-cyan-600 hover:underline">
          View All
        </a>
      </div>
      <button className="assign-btn mt-4">+ Assign</button>
      <div className="button-group mt-4">
        <button className="preview-btn">Preview</button>
        <button className="edit-btn">Edit</button>
      </div>
    </div>
  );
};

// Updated Dashboard Component
const Dashboard = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="main-content">
        <ContentHeader />
        <ContentDetails />
        <div className="content-wrapper">
          <div className="left-content">
            <Trainers />
            <PageContent />
          </div>
          <AssigneeSection />
        </div>
        <button onClick={handleLogout} className="logout-btn">Logout</button>
      </main>
    </div>
  );
};

// Main App Component
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;